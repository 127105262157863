import React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import { css } from "@emotion/core"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"

const BlogPage = ({ data }) => {
  return (
    <Layout>
      <SEO title="Fuego &amp; Flores blog" />
      {console.log(data)}
      {data.allShopifyArticle.nodes.map( (article) => (
        <div
          key={article.id}
          css={css`
            padding: 1rem;
            margin: -1rem;
            margin-top: 1.5rem;
            border-radius: 4px;
            transition: box-shadow 300ms;
            &:hover {
                box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
            }

            @media (max-width: 648px) {
                padding: 1.5rem;
                margin: 0;

                & > * + * {
                    margin-top: 1.5rem;
                }
            }
          `}
        >
            <Link to={'/blog/'+article.url.split('/').pop()} css={css`
                text-decoration: none;
                color: inherit;
            `}>
              <div>
                <h1 css={css`
                    margin-bottom: 0px;
                `}>
                    {article.title}
                </h1>
                <div css={css`
                    display: flex;
                    @media (max-width: 648px) {
                        flex-direction: column;
                        align-items: center;
                    }
                `}>
                    <Img fluid={article.image.localFile.childImageSharp.fluid} css={css`
                        min-width: 200px;
                        width: 200px;
                        height: 200px;
                        min-height: 200px;
                    `}/>
                    <div css={css`
                        margin-top: 1rem;
                        margin-left: 2rem;
                        @media (max-width: 648px) {
                            margin-left: 0;
                        }
                    `}>
                        {article.excerpt}
                    </div>
                </div>
              </div>
          </Link>
        </div>
      ))}
    </Layout>
  )
}

export default BlogPage

export const query = graphql`
  {
    allShopifyArticle(sort: {fields: publishedAt, order: DESC}) {
      nodes {
        id
        url
        title
        excerpt
        publishedAt(formatString: "ddd, MMMM Do, YYYY")
        image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 200, maxHeight: 200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
        }
      }
    }
  }  
`